<template>
  <div class="mb-4">
    <v-card flat>
      <v-toolbar
        flat
        color="gray lighten-4"
      >
        <v-toolbar-title>{{  $t('mediaHeader') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain type="plain">
          {{ $t('add') }}
        </v-btn>
      </v-toolbar>

      <div v-if="hasNoMedia" class="text-center mb-0 text-sm-body-2">
        {{ $t('noMedia') }}
      </div>

      <v-list>
        <v-list-item
          v-for="media in person.media"
          :key="media.id"
          class="pa-0"
          >
          <v-card
            :max-width="cardWidth"
          >
            <v-card-text color="red lighten-5" class="pa-0">
              <media-preview
                :value="media"
                :height="150"
                :width="150"/>
            </v-card-text>
            <v-card-actions class="p-0" color="blue lighten-5">
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  x-small>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-item>

      <v-list-group
        v-for="collection in person.collections"
        :key="collection.id"
        :value="true"
        :prepend-icon="'mdi-folder-table'"
        class="bg-green"
      >

        <template v-slot:activator>
          <v-list-item-title>
            {{ collection.collectionNumber }}
            <v-btn
                icon
                :to="{name: 'collection-detail', params: { collectionId: collection.id}}"
                target="_blank"
                :title="$t('collectionView')"
                @click.stop
              >
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
          </v-list-item-title>
        </template>

        <v-list-item
        >
          <v-card
            flat
            class="p-0"
          >
            <v-card-text>
              <v-row >
                <v-col v-for="media in collection.media" :key="media.id" >
                  <v-card
                    :max-width="cardWidth"
                  >
                    <v-card-text color="red lighten-5" class="pa-0">
                      <media-preview
                        :value="media"
                        :height="150"
                        :width="150"/>
                    </v-card-text>
                    <v-card-actions class="p-0" color="blue lighten-5">
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        x-small
                        :title="$t('makeKeyImage')"
                      >
                          <v-icon>mdi-image</v-icon>
                        </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </v-list-item>
      </v-list-group>

      </v-list>

    </v-card>
  </div>
  </template>

  <script>
  import MediaPreview from '@/components/media/media-preview.vue'
  export default {
    components: {
      MediaPreview
    },
    props: {
      person: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        cardWidth: 150
      }
    },
    computed:{
      hasNoMedia(){
        return this.person.media.length === 0 && this.person.collections.length === 0
      }
    },
    watch:{
      person(){
      }
    },
    mounted(){
    },
    methods:{
    }
  }
  </script>
