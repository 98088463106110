<template>
    <v-menu bottom left offset-y pa-3>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <!-- <v-list-item
              @click="changeProfilePicture"
            >
              <v-list-item-icon>
                <v-icon>mdi-camera-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('profilePicture') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-divider></v-divider>
            <v-list-item
              v-if="!person.isDeleted"
              @click="deletePerson"
            >
              <v-list-item-content class="text-right">
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>


</template>


<script>
import { mapActions } from 'vuex'
import personApi from '@/services/api/person'

export default{
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('messages', ['addError']),
    async deletePerson(){
      await personApi.delete(this.person.id)
      this.$router.push({ name: 'people-index' })
    },
    async changeProfilePicture(){


    }
  },
  errorCaptured(err, vm, info){
    console.log('errorCaptured')
    console.log(err, vm, info)
    this.addError(err)
    return false
  }
}
</script>
