<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>

      <v-btn
        plain
        :to="{ name: 'person-edit', params: { personId: model.id } }"
      >
        Edit
      </v-btn>
      <detail-menu :person="model" />
    </app-toolbar>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
       <flns-text-field label="Name" :text="model.name" />
       <flns-text-area label="Description" :text="model.description" />
       <flns-field-card label="Collections" v-if="model.collections.length > 0">
        <div
          class="pl-0"
          v-for="collection in model.collections" :key="collection.id"
        >
              <v-btn
                plain
                :to="{name: 'collection-detail', params: { collectionId: collection.id}}"
                target="_blank"
                title="View Collection"
              >
                {{ collection.collectionNumber }}
              </v-btn>
        </div>
       </flns-field-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          flat
          class="d-flex flex-column justify-center align-end"
          height="100%"
          width="100%"
          color="grey lighten-4"
        >
          <v-card-text class="margin-auto text-center">
            <v-card
            class="mx-auto"
            max-width="325px"
            min-height="125px"
            max-height="325px"
            v-if="model.keyImage"
            >
            <media-image
              v-model="model.keyImage"
            />
          </v-card>
          <p v-else>{{ $t("addKeyImage") }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <timeline :person="model" />

    <media :person="model" />

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import mediaImage from '@/components/media/media-image'
import personApi from '@/services/api/person'
import detailMenu from './menu.vue'
import media from './media.vue'
import timeline from './timeline.vue'

export default {
  components: { appToolbar, media, detailMenu, mediaImage, timeline },
  data() {
    return {
      isLoading: true,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('people'),
          to: { name: 'people-index' },
          disabled: false
        }
      ]

      if(this.model){
        items.push({ text: this.model.name, disabled: true })
      }

      return items
    },
    personId() {
      return this.$route.params.personId
    },
    allMedia(){
      const media = [...this.model.media]

      const collectionMedia = this.model.collections.reduce((acc, collection) => {
        return acc.concat(collection.media)
      }, [])

      console.log('collectionMedia', collectionMedia)
      media.push(...collectionMedia)

      console.log('media', media)
      return media
    }
  },
  async mounted() {
    this.model = await personApi.fetch(this.personId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToIndex()
    },

    async onSave(item) {
      await personApi.put(this.personId, item)
      this.addMessage({
        message: `${this.$t('person')} '${item.name}' ${this.$t('hasBeenUpdated')}`
      })
      this.returnToIndex()
    },
    returnToIndex() {
      this.$router.push({
        name: 'people-index'
      })
    }
  }
}
</script>

<style></style>
