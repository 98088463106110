<template>
<div class="mb-4" >
  <v-card
    flat
  >
    <!-- <v-card-title>Timeline</v-card-title> -->
    <v-toolbar
      flat
      color="gray lighten-4"
    >
      <v-toolbar-title>Timeline</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn plain type="plain">
        Add
      </v-btn> -->
    </v-toolbar>
    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :items-per-page="5000"
      :hide-default-footer="true"
      class="elevation-1"
      w-full
    >
      <template v-slot:item.eventLocalDate="{ item }">
        {{ item.eventLocalDate | dateFormatDtg }}
      </template>
    </v-data-table>
  </v-card>

  <v-divider class=""></v-divider>
</div>
</template>
<script>
export default {
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      headers: [
          {
            text: 'Date',
            align: 'start',
            sortable: true,
            value: 'eventLocalDate',
          },
          { text: 'Event', value: 'eventType' },
          { text: 'POC', value: 'pointOfContact' },
          { text: 'Notes', value: 'notes' }
        ],
      items: [
          { eventLocalDate: '2022-11-23', eventType: 'Arrest', pointOfContact: 'Off Smith', notes: 'Grid #'},
          { eventLocalDate: '2022-12-03', eventType: 'Transfer', pointOfContact: 'Off Jones', notes: 'Niamey - Site 1'},
          { eventLocalDate: '2023-01-23', eventType: 'Appearance', pointOfContact: 'Mag Adams', notes: 'Court #2'}
      ]
    }
  }
}
</script>
